<template>
	<div>
		<div class="simple-title">Business Log In<span class="fal fa-store"></span></div>
		<div class="form-wrapper small">
			<div class="tabs"><router-link to="/login/customer">Customer<span class="fal fa-user"></span></router-link><router-link to="/login/business">Business<span class="fal fa-store"></span></router-link></div>
			<div class="form-container">
				<a href @click.prevent="() => loginDriver('business', 'square')" class="square-button"><img src="/images/third-party-logos/square.png" alt="">Log In Via Square</a>
				<div class="oauth-buffer"></div>
				<div class="text">
					<p>If you're searching for a way to log in with credentials other than your Square account, that isn't possible. All businesses on Fine Grounds signed up via Square.</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import { mapGetters } from 'vuex';
import SocialAuthMixin from '@/mixins/SocialAuthMixin';

export default {

	name: 'BusinessLoginPage',

	mixins: [ SocialAuthMixin ],

	data() {
		return {
			form: {},
		};
	},

	computed: mapGetters(['hasIntended', 'intendedRoute']),

};

</script>
